export const menuItems = [
  {
    label: "home",
    icon: "bx-home",
    routeName: "ClientDashboard"
  },
  {
    label: "bookshelf",
    icon: "bx-book-open",
    subItems: [
      {
        label: "my-books",
        routeName: "ViewBooks"
      },
      {
        label: "add-book",
        routeName: "AddBook"
      }
    ]
  },
  {
    label: "sales",
    icon: "bx-pie-chart-alt-2",
    subItems: [
      {
        label: "daily-sales-report",
        routeName: "DailySalesReport"
      },
      {
        label: "sales-by-channel",
        routeName: "SalesBySalesChannel"
      },
      {
        label: "sales-by-title",
        routeName: "SalesByTitle"
      },
      {
        label: "sales-by-territory",
        routeName: "SalesByTerritory"
      }
    ]
  },
  {
    label: "royalties",
    icon: "bx-money",
    subItems: [
      {
        label: "royalty-report",
        routeName: "RoyaltyReport"
      },
      {
        label: "payment-history",
        routeName: "PaymentHistory"
      }
    ]
  },
  {
    label: "promotions",
    icon: "bx-store",
    subItems: [
      {
        label: "new-promotion",
        routeName: "AddPromotion"
      },
      {
        label: "promotion-history",
        routeName: "ViewPromotions"
      }
    ]
  },
  {
    label: "support",
    icon: "bx-support",
    routeName: "Support"
  },
  {
    label: "account",
    icon: "bx-user-circle",
    subItems: [
      {
        label: "my-profile",
        routeName: "MyProfile"
      },
      {
        label: "payment-preferences",
        routeName: "MyProfile",
        data: {
          query: {
            tab: "payment-preferences"
          }
        }
      }
    ]
  }
].map((item, i) => ({
  ...item,
  id: i,
  subItems: (item.subItems || []).map((subitem, j) => ({
    ...subitem,
    id: j,
    parentId: i
  }))
}));
