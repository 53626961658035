<template>
  <div>
    <preloader />
    <div id="layout-wrapper">
      <HorizontalTopbar :type="topbar" :width="layoutWidth" :name="user.name" />
      <HorizontalNav />
      <div class="main-content">
        <div class="page-content">
          <div class="container">
            <slot name="header"></slot>
            <router-view />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HorizontalTopbar from "@/components/horizontal-topbar";
import HorizontalNav from "@/components/horizontal-nav";
import Footer from "@/components/footer";

import { layoutComputed } from "@/store/helpers";

export default {
  components: {
    HorizontalTopbar,
    HorizontalNav,
    Footer
  },
  data() {
    return {
      topbar: "light"
    };
  },
  computed: {
    ...layoutComputed,
    ...mapState("auth", ["user"])
  },
  created: () => {
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-sidebar");
    document.body.removeAttribute("data-layout-size");
  }
};
</script>
